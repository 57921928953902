import { useState } from "react";
import { urlApi } from "../constant";


export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
     if (userToken?.token !== undefined) {
       fetch(urlApi + "/auth.php", {
         method: "POST",
         body: JSON.stringify({
           user: userToken?.user,
           token: userToken?.token,
         }),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
           Authorization: process.env.REACT_APP_API_KEY,
         },
       })
         .then((res) => res.json())
         .then((res) => {
           if (res.token === userToken?.token) {
             return res.token;
           } else {
             localStorage.removeItem("token");
             window.location.href = "/";
             return false;
           }
         });
     } else {
       return false;
     }
   return userToken?.token;
};

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
