import moment from "moment/moment";
  const Footer = () => {
return (
  <footer className="footer bg-grey-700">
    <div className="footer_container mx-8"></div>
    <hr className="footer_divider" />

    <div className="footer_bottom mx-8">
      <span className="copyright text-sm text-grey-200">
        <a
          href="https://www.marinavltava.cz/"
          target="_blank"
          rel="noreferrer"
          className="footer_list-link text-sm text-grey-200"
        >
          © {moment().format("YYYY")} Marina Vltava
        </a>. {" "}
        Všechna práva vyhrazena.
      </span>
      <ul className="footer_list">
        <li className="footer_list-item">
          <a
            href="https://www.sekerka.cz/"
            target="_blank"
            rel="noreferrer"
            className="footer_list-link text-sm text-grey-200"
          >
            Created by Sekerka
          </a>
        </li>
      </ul>
    </div>
  </footer>
);
  }

  export default Footer;