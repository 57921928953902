import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useToken from "./components/useToken";
import { urlApi } from "./constant";
import Loading from "./loading/loading";

  
  const Header = () => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const token = useToken();
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  let isLoged = false;
  
  const logoutHandler = (e) => {
    setLoading(true);
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
       fetch(urlApi + "/logout.php", {
         method: "POST",
         body: JSON.stringify({
           user: userToken?.user,
           token: userToken?.token,
         }),
         headers: {
           "Content-Type": "application/json",
           Accept: "application/json",
           Authorization: process.env.REACT_APP_API_KEY,
         },
       })
         .then((res) => res.json())
         .then((res) => {
         
           if (res.done) {
            isLoged = true;
           }
         })
         .finally(() => setLoading(false));

         if (isLoged) {
          
           window.location.href = "/";
           e.preventDefault();
         }
      };

  let logoutOut;
  if (token) {
    logoutOut = (
      <li className="hover:bg-mvblue hover:text-white active:bg-mvblue active:text-white">
        <Link
          href="#"
          onClick={(e) => logoutHandler(e)}
          className="hover:bg-mvblue hover:text-white"
        >
          Odhlásit
        </Link>
      </li>
    );
  } else {
    logoutOut = null;
  }
 return (
   <>
     <Loading
       loadingbool={loading}
       load={"home"}
       {...(!loading && { className: "hidden" })}
     />
     <header className="header bg-white">
       <a href="/" className="logo text-mvblue">
         Vouchers Marina Vltava
       </a>
       <input
         className="menu-btn"
         type="checkbox"
         id="menu-btn"
         checked={checked}
         onChange={handleChange}
       />
       <label className="menu-icon" htmlFor="menu-btn">
         <span className="navicon"></span>
       </label>
       <ul className="menu bg-white">
         <li className="hover:bg-mvblue hover:text-white focus:bg-mvblue focus:text-white">
           <Link
             to="/"
             onClick={() => setChecked(false)}
             className={
               url === "/" ? "bg-mvblue text-white" : "hover:text-white"
             }
           >
             Vytvořit
           </Link>
         </li>
         <li className="hover:bg-mvblue hover:text-white">
           <Link
             to="/list"
             onClick={() => setChecked(false)}
             className={
               url === "/list" ? "bg-mvblue text-white" : "hover:text-white"
             }
           >
             Seznam
           </Link>
         </li>
         {logoutOut}
       </ul>
     </header>
   </>
 );
};

export default Header;
