import React from "react";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return <p className="error">{this.props.props.error}</p>;
  }
}


export default Error;
