import React from "react";

const Page404 = () => {
        document.title = "ERROR 404";
  return (
    <section className="main bg-grey-300">
      <h1>ERROR 404</h1>
    </section>
  );
};

export default Page404;
