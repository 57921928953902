import React from "react";
import { useAuthDispatch, logout, useAuthState } from "../Auth";

function Dashboard(props) {
  const dispatch = useAuthDispatch(); // read dispatch method from context
  const userDetails = useAuthState(); //read user details from context

  const handleLogout = () => {
    logout(dispatch); //call the logout action

    window.location.href ="/login"; //navigate to logout page on logout
  };
  return (
    <div style={{ padding: 10 }}>
      <div >
        <h1>Dashboard</h1>
        <button  onClick={handleLogout}>
          Logout
        </button>
      </div>
      <p>Welcome {userDetails.user}</p>
    </div>
  );
}

export default Dashboard;
