import { useState } from "react";
import { urlApi, urlVoucher } from "./constant";
import Loading from "./loading/loading";

const url = urlApi + "/save.php";

const openInNewTab = (url) => {
  window.open(
   urlVoucher + "/?id=" + url,
    "_blank"
  );
};

function Home() {
      document.title = "Vytvořit nový voucher";
  const [dto, setDto] = useState("");
  const [dfrom, setDfrom] = useState("");
  const [person, setPerson] = useState("");
    const [time, setTime] = useState("");
  const [tel, setTel] = useState("606 613 173");
    const[message, setMessage] = useState("");
    const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
setLoading(true);
        await fetch(url, {
          method: "POST",
          body: JSON.stringify({
            person: person,
            tel: tel,
            dfrom: dfrom,
            dto: dto,
            time: time,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: process.env.REACT_APP_API_KEY,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status >= 400 && res.status < 600) {
              setMessage(res.status);
              setId("");
            } else {
                if (Object.entries(res.errorMap).length !== 0) {
                  setMessage(res.errorMap.error);
                  setId("");
                } else {
                  setDfrom("");
                  setDto("");
                  setPerson("");
                  setTime("");
                  setTel("606 613 173");
                  setMessage("Voucher byl úspěšně vygenerován a můžete ho stáhnout.");
                  setId(res.data.id);
                }
        }
          })
          .catch((err) => {
            setMessage("error: "  + err.message);
            setId("");
          })
          .finally(() => setLoading(false));
      
  };

  return (
    <section className="main bg-grey-300">
      <h1>Vytvoření voucheru</h1>
      <Loading
        loadingbool={loading}
        load={"home"}
        {...(!loading && { className: "hidden" })}
      />
      <div className="blok" {...(loading && { className: "hidden" })}>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            <div className="label-form">
              <label htmlFor="number-person" className="text-mvblue">
                Počet osob:
              </label>
              <input
                className="border-grey-500 focus:border-mvblue"
                id="number-person"
                type="number"
                name="person"
                value={person}
                onChange={(e) => setPerson(e.target.value)}
                placeholder="11"
                required
              />
            </div>
            <div className="label-form">
              <label htmlFor="phone" className="text-mvblue">
                Tel. pro objednání:
              </label>
              <input
                className="border-grey-500 focus:border-mvblue"
                type="tel"
                id="phone"
                name="phone"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                pattern="[0-9]{3} [0-9]{3} [0-9]{3}"
                required
              />
              <small>Format: 123 456 789</small>
            </div>
            <div className="label-form">
              <label htmlFor="time" className="text-mvblue">
                Délka plavby (1 h nebo 30 min):
              </label>
              <select
                className="border-grey-500 focus:border-mvblue"
                id="time"
                name="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              >
                <option value="1">1 h</option>
                <option value="30">30 min</option>
              </select>
            </div>
            <div className="label-form">
              <label htmlFor="date-from" className="text-mvblue">
                Platnost od:
              </label>
              <input
                className="border-grey-500 focus:border-mvblue"
                type="date"
                id="date-from"
                name="from"
                value={dfrom}
                onChange={(e) => setDfrom(e.target.value)}
                required
              />
              <small className="text-grey-500">
                Datum od kdy je možné využít certifikát
              </small>
            </div>
            <div className="label-form">
              <label htmlFor="date-to" className="text-mvblue">
                Platnost do:
              </label>
              <input
                className="border-grey-500 focus:border-mvblue"
                type="date"
                id="date-to"
                name="to"
                value={dto}
                onChange={(e) => setDto(e.target.value)}
                required
              />
              <small className="text-grey-500">
                Datum do kdy je možné využít certifikát
              </small>
            </div>
          </div>
          <div className="gap-5">
            <input
              type="submit"
              className="bg-mvblue text-white"
              value="Vygenerovat voucher"
            />
          </div>
          <div>
            {message ? (
              <p className="error bg-red50 text-red-700">{message}</p>
            ) : null}
          </div>
          <div className="gap-5">
            {id ? (
              <button
                onClick={() => openInNewTab(id)}
                className="bg-green-700 text-white"
              >
                Zobrazit voucher
              </button>
            ) : null}
          </div>
        </form>
      </div>
    </section>
  );
}

export default Home;
