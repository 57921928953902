import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./loading/loading";
import Login from "./components/Login/Login";
import Header from "./header";
import List from "./list";
import Home from "./home";
import Page404 from "./page404";
import Dashboard from "./components/Dashboard/Dashboard";
import Preferences from "./components/Preferences/Preferences";
import useToken from "./components/useToken";
import Footer from "./footer";


function App() {
  const { token, setToken } = useToken();
  if (!token) {
  return <Login setToken={setToken} />;
  }

    if (navigator.onLine) {
      return (
        <BrowserRouter>
          <Header />
          <Routes>
            <Route
              exact
              path="/"
              element={!token ? <Login setToken={setToken} /> : <Home />}
            />
            <Route
              exact
              path="/list"
              element={!token ? <Login setToken={setToken} /> : <List />}
            />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/preferences" element={<Preferences />} />
            <Route path="" element={<Page404 />} />
            <Route path="*" element={<Page404 />} />
            <Route component={() => <Page404 />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      );
    } else {
      return <Loading load="home" />;
    }
}


export default App;
