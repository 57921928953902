import React from "react";
import { useFetch } from "./useFetch";
import Loading from "./loading/loading";
import Error from "./error";
import Voucher from "./voucher";
import { urlApi } from "./constant";

const url = urlApi + "/voucher.php";

const Vouchers = () => {
        document.title = "Seznam vydaných voucherů";
  const { loading, products, errors } = useFetch(url, "GET", null);
  const load = () => {
    return <Loading load="component" loadingbool="true" />;
  };

  const getData = () => {

    if (!products && Object.keys(errors).length === 0) { return "Zatím nebyl vytvořen žádný voucher.";}

    return (
      <div>
        <section className="products-web">
          {Object.keys(errors).length === 0 ? (
            products.map((product) => {
              return <Voucher key={product.id} {...product} />;
            })
          ) : (
            <Error props={errors} />
          )}
        </section>
      </div>
    );
  };

  return loading ? load() : getData();
};

export default Vouchers;
