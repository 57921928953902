import React, { useState } from "react";
import PropTypes from "prop-types";
import { urlApi } from "../../constant";
import Loading from "../../loading/loading";

import "./Login.css";



export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  
  const [loading, setLoading] = useState(false);

     localStorage.removeItem("token");
async function loginUser(credentials) {
  return fetch(urlApi + "/login.php", {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: process.env.REACT_APP_API_KEY,
    },
  })
    .then((res) => res.json())
    .then((res) => {

      if (res.status >= 400 && res.status < 600) {
        setMessage(res.status);
        setPassword("");
      } else {
        if (Object.entries(res.errorMap).length !== 0) {
          setMessage(res.errorMap.error);
          setPassword("");
        } else {
          setPassword("");
          return res.data;
        }
      }
    })
    .catch((err) => {
      setMessage(err.message);
      setPassword("");
            console.log(err);
    })
    .finally(() => {
    setLoading(false);
    });
}
    const handleSubmit = async (e) => {
      setLoading(true);
      e.preventDefault();
      const token = await loginUser({
        username,
        password,
      });
      if (token !== undefined) {
      setToken(token);
      }
    };

  return (
    <>
      <header className="header">
        <a href="/" className="logo text-mvblue">
          Vouchers Marina Vltava
        </a>
      </header>
      <Loading
        loadingbool={loading}
        load={"home"}
        {...(!loading && { className: "hidden" })}
      />
      <div
        className="login-wrapper bg-grey-300"
        {...(loading && { className: "hidden" })}
      >
        <h1>Prosím přihlašte se</h1>
        <form onSubmit={handleSubmit}>
          <label className="text-mvblue">
            <p>Uživatelské jméno</p>
            <input
              className="border-grey-500 focus:border-mvblue"
              type="text"
              value={username || ""}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </label>
          <label className="text-mvblue">
            <p>Heslo</p>
            <input
              className="border-grey-500 focus:border-mvblue"
              type="password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>
          <div>
            <input
              type="submit"
              className="text-white bg-mvblue"
              value="Přihlásit"
            />
          </div>
          <div className="text-red-700">
            {message ? <p className="error bg-red50">{message}</p> : null}
          </div>
        </form>
      </div>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
