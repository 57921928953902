import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { urlVoucher } from "./constant";

const openInNewTab = (url) => {
  window.open(
    urlVoucher + "/?id=" + url,
    "_blank",
    "noopener,noreferrer"
  );
};

moment.locale("cs");
class Voucher extends React.Component {
    
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    moment.locale("cs");
    return (
      <div className="grid grid-cols-1 md:grid-cols-5 voucher gap-5">
        <div className="con-text">
          <h2>Číslo voucheru: {this.props.code || ""}</h2>
        </div>
        <div className="con-text"> Počet osob: {this.props.person || ""}</div>
        <div className="con-text">Kapitán: {this.props.tel || ""}</div>
        <div className="con-text">
          {" "}
          Platnost: od {moment(this.props.dfrom).format("D. M. Y") || ""} do{" "}
          {moment(this.props.dto).format("D. M. Y") || ""}
        </div>
        <div className="con-text">
          <button className="bg-mvblue text-white" onClick={() => openInNewTab(this.props.id)}>
            zobrazit voucher
          </button>
        </div>
      </div>
    );
  }
}

Voucher.propTypes = {
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  person: PropTypes.string.isRequired,
  tel: PropTypes.string.isRequired,
  dfrom: PropTypes.string.isRequired,
  dto: PropTypes.string.isRequired,
};

export default Voucher;
