import React from "react";
import Loading from "./loading/loading";
import Vouchers from "./vouchers";

const List = () => {
if (navigator.onLine) {
  return (
    <section className="main bg-grey-300">
      <h1>Seznam vydaných voucherů</h1>
                <div className="blok">
                  <Vouchers />
                </div>
    </section>
  );
    } else {
      return <Loading load="home" loadingbool="true" />;
    }};

export default List;
