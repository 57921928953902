import { useState, useEffect, useCallback } from "react";

export const useFetch = (url, method, body) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const [errors, setError] = useState({});
  const getProducts = useCallback(async () => {
    await fetch(url, {
      method: method,
      body: body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          setError({ error: "Error code: " + res.status });
        } else {
          setProducts(res.data);
          setError(res.errorMap);
        }
      })
      .catch((err) => {
        setError({ error: "" + err.message });
      })
      .finally(() => setLoading(false));
  }, [url, method, body]);

  useEffect(() => {
    getProducts();
  }, [url, getProducts]);
  return { loading, products, errors };
};
