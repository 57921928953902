import React from "react";
import PropTypes from "prop-types";

const Loading = ({ load, loadingbool }) => {
  const stylable = loadingbool ? "home" : "home hidden";
  return (
    <div className={stylable}>
      <div className="loader-center">
        <div className="loader" />
      </div>
    </div>
  );
};
Loading.propTypes = {
  load: PropTypes.string.isRequired,
};
export default Loading;
